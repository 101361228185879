import React from 'react';
import { Link } from 'gatsby';
import projects from '../../assets/data/projects.json';

const CaseStudiesList = () => {
  return (
    <div className="projects-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          {projects?.map((project) => (
            <div className="col-lg-6 col-md-6">
              <Link to={project.link} className="link-btn">
                <div className="single-projects-box">
                  <div className="image">
                    <img src={project.image} alt="about" />
                  </div>

                  <div className="content">
                    <h3>{project.name}</h3>
                    <span>{project.tech}</span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudiesList;
