import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from '../../components/App/Navbar';
import PageBanner from '../../components/Common/PageBanner';
import Footer from '../../components/App/Footer';
import CaseStudiesList from '../../components/CaseStudies/CaseStudiesList';
import StartProject from '../../components/Common/StartProject';

const OurWorkPage = () => {
  return (
    <Layout title="Our Work">
      <Navbar />
      <PageBanner
        pageTitle="Our Work"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Our Work"
      />
      <CaseStudiesList />
      <StartProject />
      <Footer />
    </Layout>
  );
};

export default OurWorkPage;
